import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './i81n';


ReactDOM.render(<App />, document.getElementById('root')
);


